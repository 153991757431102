@import '../../../../assets/styles/mixins.scss';

.menu {
  :global(.ant-menu-item-divider) {
    height: 36px !important;
    margin-top: 6px !important;
    width: 1px !important;
    float: left;
  }

  :global(.ant-menu-dark) {
    :global(.ant-menu-item-divider) {
      background: $text-darken-more !important;
    }

    .icon {
      color: $gray-darken;
    }

    .title {
      color: $gray-darken;
    }
  }

  :global(.ant-menu-light) {
    .icon {
      color: $text;
    }

    .title {
      color: $text;
    }
  }
}

.icon {
  margin-top: rem(16);
  margin-right: rem(7);

  @media (max-width: $md-max-width) {
    display: none !important;
  }
}

:global(.settings__menuTop .ant-menu-light) {
  border-bottom: none;
  box-shadow: inset 0 -1px 0 #e8e8e8;
}

.menuTopItem {
  padding: 0 6px;
}

.menuTopWrapper {
  display: flex;
  padding-inline: 10px 13px;
}

.freeSpaceEater {
  flex: 1;
}
