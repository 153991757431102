@import "../../../../assets/styles/mixins.scss";

.popoverContent {
  width: 384px;
}

.hoverable {
  cursor: pointer;
}

.menuIcon {
  background-color: $menuBg;
}

.notificationOverlayWrapper {
  :global {
    position: fixed;
    top: 54px !important;

    .ant-popover-arrow {
      top: 0 !important;
      right: 9px !important;
      transform: translateY(-100%);
    }

    .ant-popover-arrow:before {
      background-color: $blue-lighten-ant-default;
    }

    .ant-popover-content {
      .ant-popover-inner {
        padding: 0;
        .ant-popover-inner-content {
          padding: 6px 12px;
          max-height: 500px;
          overflow-y: auto;

          .ant-list-item:hover {
            opacity: 0.6;
          }

          .ant-list-item-meta {
            display: flex;
            align-items: center;

            .ant-list-item-meta-title {
              margin-bottom: 0;
              font-weight: 400;
            }

            .ant-list-item-meta-avatar {
              .anticon-info-circle {
                color: $blue-lighten-ant-default !important;
              }
            }
          }
        }

        .ant-popover-title {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          background-color: $blue-lighten-ant-default;
          color: white;
          margin-bottom: 0;
          padding: 10px 20px;
          font-size: 1.5rem;
          font-weight: 500;
        }
      }
    }
  }
}
